<template>
  <div v-if="pagination" class="flex items-center justify-center gap-2">
    <CommonLink
      :href="prevLink"
      :aria-label="$t('pagination.prev-page')"
      :class="paginationCva({ type: 'previous', disabled: !prevLink })"
      @mouseenter="prefetch('prev')"
    >
      <Icon name="mingcute:left-line" class="z-10 text-2xl" />
    </CommonLink>

    <div class="flex max-w-[10rem] items-center">
      <div class="relative">
        <FormKit type="form" :actions="false" @submit="handleSubmit">
          <label class="sr-only" for="currentPage">
            {{ $t('current-page') }}
          </label>
          <div class="-mt-6">
            <FormKit
              id="currentPage"
              ref="inputRef"
              type="number"
              :value="catalogNumber"
              name="Page"
              :min="1"
              :max="pagination.lastCatalogNumber"
              :validation="`number|min:0|bidValidation|max:${pagination.lastCatalogNumber}`"
              validation-visibility="live"
              :disabled="!pagination || pagination?.total === 1"
              @blur="handleSubmit"
            />
            <FormKitMessages
              class="absolute -bottom-6 whitespace-nowrap"
              :node="inputRef?.node"
            />
          </div>
          <button type="submit" class="sr-only">{{ $t('submit') }}</button>
        </FormKit>
      </div>
      <div class="flex items-center gap-2 pl-3 pr-1 font-sans text-[#343434]">
        <span>{{ $t('from') }}</span>
        <span>{{ pagination.lastCatalogNumber }}</span>
      </div>
    </div>

    <CommonLink
      :href="nextLink"
      :aria-label="$t('pagination.next-page')"
      :class="paginationCva({ type: 'next', disabled: !nextLink })"
      @mouseenter="prefetch('next')"
    >
      <Icon name="mingcute:right-line" class="z-10 text-2xl" />
    </CommonLink>
  </div>
</template>

<script lang="ts" setup>
import { FormKitMessages } from '@formkit/vue'
import { useCarPaginationLinks } from '@autobid/ui/composables/car/useCarPaginationLinks'
import { usePrefetchPagination } from '@autobid/ui/composables/car/usePrefetchPagination'
import { useCustomFetch } from '@autobid/ui/composables/useHttp'
import { useMutation } from '@tanstack/vue-query'
import { useGetCarLink } from '@autobid/ui/composables/car/useGetCarLink'
import { paginationCva } from '@autobid/ui/utils/paginationCva'
import type { CarInputPaginationProps } from '@autobid/ui/types/components/CarInputPaginationProps'
import { getError } from '@autobid/strapi-integration/utils/getError'
import { ref } from 'vue'

type CarSlug = {
  data: {
    items: {
      items: {
        slug: string
      }[]
    }
  }
}

const props = defineProps<CarInputPaginationProps>()

const query = `query GetCarSlugByCatalogNumbers($catalogNumbers: [Int], $auctionIds: [Int], $stages: [ItemStage!] = [], $publicationStatus: [ItemPublicationStatus!] = []) {
  items(params:{catalogNumbers: $catalogNumbers, auctionIds: $auctionIds, stages: $stages, publicationStatus: $publicationStatus, includeNotVisibleInList: true}) {
    items {
    	slug
    }
  }
}`

const { $customFetch } = useCustomFetch()
const { t } = useI18n()
const mutationFn = async ({
  variables
}: {
  variables: { catalogNumbers: number[] }
}) => {
  const result = await $customFetch<CarSlug>('/api/backend', {
    method: 'POST',
    body: {
      queryApi: 'auctions',
      queryMethod: 'POST',
      queryUrl: '/api/v1/query',
      query,
      variables
    }
  })
  return result?.data?.items?.items?.[0]?.slug
}

const push = usePush()
const { mutateAsync: mutate } = useMutation({
  mutationFn,
  onSuccess: (result) => {
    if (!result) {
      push.error(t('shared-car.car-not-found'))
    }
  },
  onError: (e) => {
    push.error(getError(e).message)
  }
})

const { getLink } = useGetCarLink()
const router = useRouter()
const handleSubmit = async () => {
  const inputValue = Number(inputRef.value.node.value)
  if (inputValue === props.catalogNumber) {
    return
  }
  const variables = {
    catalogNumbers: [inputValue],
    auctionIds: [props.auctionId],
    stages: ['BEFORE_AUCTION', 'IN_AUCTION', 'FINISHED'],
    publicationStatus: ['PUBLISHED']
  }

  const result = await mutate({
    variables
  })
  const link = getLink(result, props.type)
  if (!link) {
    return
  }
  router.push(link)
}

const inputRef = ref()

const { pagination, prevLink, nextLink } = useCarPaginationLinks(
  props.id,
  props.type
)

const { prefetch } = usePrefetchPagination(pagination)
</script>
