import { unref, type MaybeRef, ref, onMounted } from 'vue'
import { useCar } from '@autobid/ui/composables/useCar'
import type { AuctionCarPagination } from '@autobid/ui/types/Car'
import { useCarPageInfo } from './useCarPageInfo'

export const usePrefetchPagination = (
  pagination: MaybeRef<AuctionCarPagination>
) => {
  const prefetchable = ref(false)
  const { pageType } = useCarPageInfo()
  const { id, prefetchCar } = useCar({
    type: pageType.value === 'details' ? 'details' : 'barometer'
  })

  const prefetch = async (type: 'next' | 'prev') => {
    if (!prefetchable.value) return

    prefetchable.value = false

    const link =
      type === 'prev' ? unref(pagination).previous : unref(pagination).next

    if (!link) return

    id.value = Number(link.split('-').reverse()[0])

    await prefetchCar({ omitAuction: true, omitCounters: true })

    prefetchable.value = true
  }

  onMounted(() => {
    setTimeout(() => {
      prefetchable.value = true
    }, 200)
  })

  return {
    prefetch
  }
}
