import auctionSlugs from '@autobid/apps-nuxt-config/i18n/auctionSlugs'

export function useGetCarLink() {
  const { locale } = useI18n()

  const getLink = (slug: string | undefined, type: 'details' | 'barometer') => {
    if (!slug) return

    return `/${locale.value}${
      auctionSlugs[`item/[slug]${type === 'details' ? '/details' : '/index'}`][
        locale.value
      ]
    }`.replace('[slug]', slug)
  }

  return {
    getLink
  }
}
